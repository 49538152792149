import { DataLayerObject } from "../my-account-tools.d";

declare global {
  interface Window {
    dataLayer?: DataLayerObject[];
  }
}

export const dataLayer = (): DataLayerObject[] => {
  return window.dataLayer || [];
};

export const push = (dataLayerObject: DataLayerObject) => {
  dataLayer().push(dataLayerObject);
};
