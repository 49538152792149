import "./@types/ga.d";

export declare const auth: {
  LOGIN_ERROR_MESSAGE: string;
  getUser: () => any;
  isLoggedIn: () => boolean;
  login: () => void;
  loginWithContext: (
    currentLocation?: string,
    additionalScope?: string,
    additionalRequestParameters?: object,
    loginState?: string
  ) => void;
  getToken: () => Promise<string>;
  logout: (pathName?: string) => void;
};
export declare const telemetry: {
  page: (name: string, properties: object) => void;
  segmentTrack: (name: string, properties?: object) => void;
  track: (
    name: string,
    properties: {
      action: string;
      label?: string;
      createData?: object;
      clickData?: object;
      viewData?: object;
      editData?: object;
    },
    customisedContext?: {}
  ) => void;
  analytics: () => SegmentAnalytics.AnalyticsJS;
  group: (name: string) => void;
  MOCAGroup: (name: string, traits?: Record<string, string>) => void;
  identify: (name: string, traits?: Record<string, string>) => void;
  initSegment: (init?: () => void) => void;
  initBusinessId: () => {
    setBusinessId: (id: string) => void;
    getBusinessId: () => string;
  };
};
export declare const ga: {
  push: (dataLayerObject: DataLayerObject) => void;
};
export declare const helper: {
  getRegionFromTimezone: () => Region;
};
export declare const splitToggles: {
  init: (userIdentifier: string) => Promise<string>;
  isToggleOn: (splitName) => boolean;
};

export declare const GtmManager: {
  dataLayer: (dataLayerArgs: DataLayerArgs) => void;
  initialize: (tagManagerArgs: TagManagerArgs) => void;
};

export declare const checkFeelixVersion: (
  projectName: string,
  projectFeelixVersion: string
) => void;

export enum Region {
  AU = "AU",
  NZ = "NZ",
}
export type DataLayerObject =
  | GA.ContentViewedObject
  | GA.APIErrorObject
  | GA.GenericFormObject
  | GA.ModalObject
  | GA.PageViewObject
  | GA.ElementClickObject;

export interface DataLayerArgs {
  dataLayer?: object | undefined;
  dataLayerName?: string | undefined;
}

export interface TagManagerArgs extends DataLayerArgs {
  gtmId: string;
  events?: object | undefined;
  auth?: string | undefined;
  preview?: string | undefined;
}

export declare const webVitals: {
  sendReportToGtm: () => void;
};
