import { SplitFactory } from "@splitsoftware/splitio";
import config from "config";

let splitIOAdapter: SplitIOAdapter;

export class SplitIOAdapter {
  TOGGLE_ON = "on";
  splitIOClient: SplitIO.IClient = null;

  init(userIdentifier: string): Promise<string> {
    const client: SplitIO.IClient = SplitFactory({
      core: {
        authorizationKey: config.SPLIT_IO_API_KEY,
        key: userIdentifier,
      },
      startup: {
        requestTimeoutBeforeReady: 1.5,
        retriesOnFailureBeforeReady: 3,
        readyTimeout: 3,
      },
      storage: {
        type: "MEMORY",
        prefix: "MY_ACCOUNT",
      },
    }).client();
    return new Promise((resolve, reject) => {
      client.on(client.Event.SDK_READY, () => {
        this.splitIOClient = client;
        resolve("SPLIT_SDK_READY");
      });
      client.on(client.Event.SDK_READY_TIMED_OUT, () => {
        reject(new Error("SPLIT_SDK_READY_TIMED_OUT"));
      });
    });
  }
  isToggleOn(splitName) {
    if (this.splitIOClient) {
      // getTreatment() will return "control" when splitName is not existing or SDK is un-reachable
      return this.splitIOClient.getTreatment(splitName) === this.TOGGLE_ON;
    }
    return false;
  }
}

export default function initialize(): SplitIOAdapter {
  if (splitIOAdapter == null) {
    splitIOAdapter = new SplitIOAdapter();
  }
  return splitIOAdapter;
}
