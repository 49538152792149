import Config from "config";
import segmentAnalyticsInit from "./analytics";
import initializeAuth from "../auth";
import { removeQueryString } from "../helpers/tools";

declare global {
  interface Window {
    analytics?: SegmentAnalytics.AnalyticsJS;
  }
}

let businessId = "";
export const initBusinessId = () => {
  return {
    setBusinessId: (id: string) => {
      businessId = id;
      if (businessId !== "") {
        group(businessId);
      }
    },
    getBusinessId: () => {
      return businessId;
    },
  };
};

export const analytics = (): SegmentAnalytics.AnalyticsJS => {
  if (window.analytics === undefined) {
    throw new Error("bug: call initSegment() beforehand");
  }
  return window.analytics;
};

const getDefaultContext = () => ({
  category: "SME", // for google
  productFamily: "SME", // for analysts
  domain: "",
  feature: "",
  sourceSystem: "MyAccount",
  businessId: initBusinessId().getBusinessId(),
  userId: initializeAuth().getUser()?.userId,
  label: "",
  timestamp: new Date(Date.now()).toISOString(),
  url: removeQueryString(window.location.href),
  client_id: JSON.parse(
    localStorage.getItem("@my-account/account:selected-account-id")
  )?.clientId,
});

const getCustomisedContext = () => ({
  context: {
    page: {
      referrer: removeQueryString(window.document.referrer),
      search: "",
      url: removeQueryString(window.location.href),
    },
  },
});

type IdentifyTraits = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  visitorId: string;
};

export const page = (name: string, properties: object) => {
  analytics().page(
    name,
    { ...getDefaultContext(), ...properties },
    getCustomisedContext()
  );
};

export const identify = (name: string, traits?: IdentifyTraits) => {
  analytics().identify(name, traits);
};

type TrackProperties = {
  action: string;
  label?: string;
  createData?: object;
  clickData?: object;
  viewData?: object;
  editData?: object;
};

type GroupTraits = {
  country: string;
};

export const segmentTrack = (name: string, properties?: object): void => {
  analytics().track(name, properties);
};

export const track = (
  name: string,
  properties: TrackProperties,
  customisedContext?: {}
) => {
  analytics().track(
    name,
    {
      ...getDefaultContext(),
      ...properties,
      ...customisedContext,
    },
    {
      context: {
        groupId: initBusinessId().getBusinessId(),
        ...getCustomisedContext().context,
      },
    }
  );
};

export const group = (name: string) => {
  analytics().group(name, getDefaultContext(), getCustomisedContext());
};

export const MOCAGroup = (name: string, traits: GroupTraits): void => {
  analytics().group(name, traits);
};

export const initSegment = (init = segmentAnalyticsInit) => {
  init();
  if (
    window.analytics &&
    !(window.analytics as unknown as any).initialize &&
    Config.SEGMENT_WRITE_KEY?.length > 0
  ) {
    window.analytics.load(Config.SEGMENT_WRITE_KEY);
  }
};
