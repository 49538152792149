import TagManager from "react-gtm-module";
import { onCLS, onFID, onLCP, onINP, onFCP, onTTFB } from "web-vitals";

const sendToDataLayer = (metric) => {
  const webVitalsMeasurement = {
    name: metric.name,
    value: metric.value,
    rating: metric.rating,
    delta: metric.delta,
    id: metric.id,
    navigationType: metric.navigationType,
  };

  TagManager.dataLayer({
    dataLayer: {
      event: "coreWebVitals",
      webVitalsMeasurement: webVitalsMeasurement,
    },
    dataLayerName: "MyAccount",
  });
};

const sendReportToGtm = () => {
  onCLS(sendToDataLayer);
  onFID(sendToDataLayer);
  onLCP(sendToDataLayer);
  onINP(sendToDataLayer);
  onFCP(sendToDataLayer);
  onTTFB(sendToDataLayer);
};

export default sendReportToGtm;
