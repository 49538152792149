import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { Region } from "../my-account-tools.d";

dayjs.extend(timezone);

const NZ_TIMEZONE = "Pacific/Auckland";
const CHATHAM_TIMEZONE = "Pacific/Chatham";
const NZ_TIMEZONE_OFFSET = -720;
const CHATHAM_TIMEZONE_OFFSET = -765;

export const removeQueryString = (origin: string): string => {
  let url: URL;
  try {
    url = new URL(origin);
  } catch {
    return "";
  }
  return url.origin + url.pathname;
};

export function isIE() {
  return navigator.userAgent.indexOf("Trident/") > -1;
}

export const isInNZ = () => {
  const date = new Date();
  const tz = date.getTimezoneOffset();
  return tz === NZ_TIMEZONE_OFFSET || tz === CHATHAM_TIMEZONE_OFFSET;
};

export const getRegionFromTimezone = (): Region => {
  if (isIE()) {
    return isInNZ() ? Region.NZ : Region.AU;
  }
  const tz = dayjs.tz.guess();
  return tz.includes(NZ_TIMEZONE) || tz.includes(CHATHAM_TIMEZONE)
    ? Region.NZ
    : Region.AU;
};
