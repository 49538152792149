import { AppConfig } from "./type";

const config: AppConfig = {
  AUTHENTICATION_AUTHORITY: "https://sit.login.myob.com",
  AUTHENTICATION_WEB_CLIENT_ID: "self-service-portal-web",
  AUTHENTICATION_BFF_CLIENT_ID: "self-service-portal-bff",
  SEGMENT_WRITE_KEY: "I16r71qZMc5d6lMdl5pyM7okZKZ1j4mZ",
  SPLIT_IO_API_KEY: "35pubi4g92pc8rhkdecnjds1lss7rods0qt4",
};

export default config;
